<template>
  <v-card flat tile>
    <v-row style="padding: 0px">
      <v-col style="padding: 0px">
        <v-skeleton-loader
          outlined
          v-bind="attrs"
          type="list-item-avatar,list-item-two-line"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="3" style="padding: 0px">
        <v-skeleton-loader
          outlined
          v-bind="attrs"
          type="actions"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    attrs: {
      class: "mb-1",
      boilerplate: false,
      elevation: 0,
      height: 160,
    },
  }),
};
</script>

<style></style>
