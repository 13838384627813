<template>
  <div>
    <!-- <Sidebar /> -->
    <HeaderPage />
    <v-stepper v-model="stepper" flat style="margin-top: 50px; width: 100%">
      <v-container>
        <v-stepper-header>
          <div class="d-flex flex-column align-center justify-center">
            <v-stepper-step
              class="background-class"
              :complete="stepper > 1"
              step="1"
              color="#ff0090"
            >
            </v-stepper-step>
            <div style="margin-top: -20%; max-width: 100xp">
              <small>{{ $t("message.label-step-checkout") }}</small>
            </div>
          </div>
          <v-divider class="black--text"></v-divider>
          <div class="d-flex flex-column align-center justify-center">
            <v-stepper-step step="2" color="#ff0090"> </v-stepper-step>
            <div style="margin-top: -20%; max-width: 100px">
              <small>{{ $t("message.order-process") }}</small>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="d-flex flex-column align-center justify-center">
            <v-stepper-step step="3" color="#ff0090"> </v-stepper-step>
            <div style="margin-top: -20%; max-width: 100px">
              <small>{{ $t("message.label-step-success") }}</small>
            </div>
          </div>
        </v-stepper-header>
      </v-container>

      <v-stepper-content step="1" style="padding: 0; margin-top: 5%">
        <v-container>
          <div class="d-flex flex-row">
            <div
              style="
                width: 7px;
                height: 100%;
                background-color: #ff0090;
                margin-right: 2%;
                margin-bottom: 2%;
              "
            >
              &nbsp;
            </div>
            <div>{{ $t("message.label-list-carts") }}</div>
          </div>
        </v-container>

        <div v-if="isLoading">
          <div v-for="i in 6" :key="i">
            <SkeletonCart />
          </div>
        </div>

        <div v-else>
          <v-container class="pb-1" v-if="dataCarts.length == 0">
            <div class="d-flex flex-row align-center justify-center mt-12">
              <h3 style="color: grey">{{ $t("message.no-cart") }}</h3>
            </div>
          </v-container>

          <div v-else>
            <v-container>
              <div
                v-for="(itemCarts, i) in dataCarts"
                :key="i"
                style="margin-bottom: 7%"
              >
                <div>
                  <div
                    class="d-flex flex-row justify-space-between"
                    style="margin-bottom: 1%; font-size: 16px"
                  >
                    <span
                      >{{ $t("message.label-merchant") }} :
                      {{ itemCarts.store_name }}</span
                    >
                    <span
                      v-if="itemCarts.is_bulk == 1"
                      style="margin-bottom: 1%; font-size: 16px"
                      >{{ $t("message.title-minimum-qty") }}:
                      {{ itemCarts.min_qty }}</span
                    >
                  </div>
                  <div v-for="(item, j) in itemCarts.detail" :key="j" style="">
                    <v-row
                      :class="item.is_stock == 0 ? 'empty_stock' : 'style-cart'"
                    >
                      <v-col
                        cols="3"
                        sm="2"
                        class="d-flex flex-column align-center justify-space-between"
                      >
                        <v-avatar
                          width="100%"
                          height="100px"
                          style="margin-left: 1%"
                          tile
                        >
                          <v-img :src="item.image_url"></v-img>
                        </v-avatar>
                        <v-btn
                          @click.prevent="deleteCart(item.carts_id)"
                          width="100%"
                          color="#808080"
                          style="color: white; font-size: 12px"
                          >{{ $t("message.label-delete") }}</v-btn
                        >
                      </v-col>
                      <v-col cols="6" sm="8" style="padding-right: 0px">
                        <div
                          :class="
                            item.is_stock == 0
                              ? 'font_empty-stock'
                              : 'font-class'
                          "
                        >
                          {{ item.item_name }}
                        </div>
                        <p class="red--text" v-if="item.is_stock == 0">
                          {{ $t("message.empty-stok") }}
                        </p>
                        <div
                          style="font-size: 14px"
                          class="d-flex flex-column align-start"
                          v-if="item.is_stock > 0"
                        >
                          <div class="d-flex flex-row">
                            <div>
                              <span
                                class="text-price text_color"
                                style="font-size: 18px"
                              >
                                ¥{{
                                  formatDecWithoutComa(
                                    item.price_display_peritem
                                  )
                                }}
                              </span>
                              x
                              <span>
                                {{ item.qty }}
                              </span>
                              <br />
                              <span style="font-size: 18px">
                                ¥{{ formatDecWithoutComa(item.price_display) }}
                              </span>
                              <span style="font-size: 14px; color: #aaaaaa">
                                {{ item.tax_type_display }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="3"
                        sm="2"
                        style="border-left: 1px solid gray"
                        class="d-flex flex-row align-center"
                        v-if="item.is_stock > 0"
                      >
                        <v-row justify="center">
                          <!-- :disabled="getCartQty(item.carts_id) == item.min_qty" -->
                          <v-icon
                            :disabled="getCartQty(item.carts_id) == 1"
                            class="plusminus"
                            color="black"
                            @click.prevent="minQty(item.carts_id, 1)"
                          >
                            mdi-minus
                          </v-icon>

                          <span
                            type="text"
                            style="
                              font-weight: bold;
                              font-size: 20px;
                              margin-left: 5%;
                              margin-right: 5%;
                            "
                          >
                            {{ getCartQty(item.carts_id) }}
                          </span>
                          <v-icon
                            class="plusminus"
                            color="black"
                            :disabled="
                              getCartQty(item.carts_id) == item.max_qty
                            "
                            @click.prevent="addQty(item.carts_id, 1)"
                          >
                            mdi-plus
                          </v-icon>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="itemCarts.is_stock > 0 && itemCarts.is_bulk == 1">
                    <v-row class="class-itemBulk">
                      <v-col
                        cols="12"
                        sm="10"
                        class="d-flex flex-column align-end"
                        style="height: 100%"
                      >
                        <div
                          class="d-flex flex-row justify-end mt-4"
                          style="width: 100%"
                        >
                          <v-card class="mx-3 d-flex" flat>
                            <v-row>
                              <v-col cols="4">
                                <div>
                                  {{ $t("message.label-send") }}
                                </div>
                                <div>
                                  <v-icon size="60"> mdi-locker </v-icon>
                                </div>
                              </v-col>
                              <v-col cols="8">
                                <div
                                  style="
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-around;
                                  "
                                >
                                  <span style="font-weight: bold">{{
                                    itemCarts.customer_address.address_name
                                  }}</span>
                                  <span style="font-size: 14px">{{
                                    itemCarts.menu_date
                                  }}</span>
                                  <span style="font-size: 12px">
                                    〒{{
                                      formatPostalcode(
                                        itemCarts.customer_address.postal_code
                                      )
                                    }}
                                    {{ itemCarts.customer_address.prefecture }}
                                    {{ itemCarts.customer_address.city }}
                                    {{ itemCarts.customer_address.district }}
                                  </span>
                                  <span style="font-size: 12px">
                                    {{ itemCarts.customer_address.ward }}
                                    {{
                                      itemCarts.customer_address.street_address
                                    }}
                                    {{ itemCarts.customer_address.building }}
                                  </span>
                                </div>
                              </v-col>
                            </v-row>
                          </v-card>
                        </div>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="itemCarts.is_stock > 0" class="class-itemEc">
                    <v-row class="py-6">
                      <v-col cols="10">
                        <v-row>
                          <v-col class="text-end pb-0" cols="9">
                            {{ $t("message.label-subtotal") }} :
                          </v-col>
                          <v-col cols="3" class="pb-0 text-end px-0">
                            <span>
                              ¥{{ formatDecWithoutComa(itemCarts.subtotal) }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="
                              itemCarts.subtotal_exclude_taxable_value_8 > 0
                            "
                          >
                            {{ $t("message.label-tax_exclude-8%") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="
                              itemCarts.subtotal_exclude_taxable_value_8 > 0
                            "
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.subtotal_exclude_taxable_value_8
                                )
                              }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="itemCarts.subtotal_consumption_tax_8 > 0"
                          >
                            {{ $t("message.label-consumption-tax-8%") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="itemCarts.subtotal_consumption_tax_8 > 0"
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.subtotal_consumption_tax_8
                                )
                              }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="
                              itemCarts.subtotal_exclude_taxable_value_10 > 0
                            "
                          >
                            {{ $t("message.label-tax-exclude-10%") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="
                              itemCarts.subtotal_exclude_taxable_value_10 > 0
                            "
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.subtotal_exclude_taxable_value_10
                                )
                              }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="itemCarts.subtotal_consumption_tax_10 > 0"
                          >
                            {{ $t("message.label-consumption-tax-10%") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="itemCarts.subtotal_consumption_tax_10 > 0"
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.subtotal_consumption_tax_10
                                )
                              }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="
                              itemCarts.subtotal_include_taxable_value_8 > 0
                            "
                          >
                            {{ $t("message.label-tax-include-8%") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="
                              itemCarts.subtotal_include_taxable_value_8 > 0
                            "
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.subtotal_include_taxable_value_8
                                )
                              }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="itemCarts.subtotal_consumption_taxable_8 > 0"
                          >
                            {{ $t("message.label-consumption-taxable_8") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="itemCarts.subtotal_consumption_taxable_8 > 0"
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.subtotal_consumption_taxable_8
                                )
                              }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="
                              itemCarts.subtotal_include_taxable_value_10 > 0
                            "
                          >
                            {{ $t("message.label-tax-include-10%") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="
                              itemCarts.subtotal_include_taxable_value_10 > 0
                            "
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.subtotal_include_taxable_value_10
                                )
                              }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="itemCarts.subtotal_consumption_taxable_10 > 0"
                          >
                            {{ $t("message.label-consumption-taxable_10") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="itemCarts.subtotal_consumption_taxable_10 > 0"
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.subtotal_consumption_taxable_10
                                )
                              }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="itemCarts.subtotal_non_taxable_value > 0"
                          >
                            {{ $t("message.label-non_taxable_value") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="itemCarts.subtotal_non_taxable_value > 0"
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.subtotal_non_taxable_value
                                )
                              }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="itemCarts.delivery_fee_display > 0"
                          >
                            {{ $t("message.label-delivery-fee") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="itemCarts.delivery_fee_display > 0"
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.delivery_fee_display
                                )
                              }}
                            </span>
                          </v-col>
                          <v-col
                            class="text-end pb-0"
                            cols="9"
                            v-if="
                              itemCarts.total_amount_w_delivery_fee_per_store >
                              0
                            "
                          >
                            {{ $t("message.label-total") }} :
                          </v-col>
                          <v-col
                            cols="3"
                            v-if="
                              itemCarts.total_amount_w_delivery_fee_per_store >
                              0
                            "
                            class="text-end pb-0 px-0"
                          >
                            <span>
                              ¥{{
                                formatDecWithoutComa(
                                  itemCarts.total_amount_w_delivery_fee_per_store
                                )
                              }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="itemCarts.is_bulk == 1">
                    <v-col
                      class="d-flex flex-column align-center"
                      style="margin-top: 2%"
                      v-if="itemCarts.is_stock > 0 && itemCarts.is_bulk == 1"
                    >
                      <v-row
                        ><v-btn
                          @click.prevent="$router.push('/bulk')"
                          width="200px"
                          style="
                            margin-bottom: 2%;
                            border-color: #ff0090;
                            background-color: transparent;
                          "
                          outlined
                          >{{ $t("message.label-continue-shop") }}</v-btn
                        ></v-row
                      >
                      <v-row
                        ><v-btn
                          @click.prevent="
                            handleClearCartsBulk(
                              itemCarts.store_id,
                              itemCarts.group_id
                            )
                          "
                          width="200px"
                          style="
                            margin-bottom: 2%;
                            border-color: #808080;
                            background-color: gray;
                            color: white;
                          "
                          outlined
                          >{{ $t("message.label-clear-carts") }}</v-btn
                        ></v-row
                      >
                      <v-row
                        ><v-btn
                          :disabled="itemCarts.total_qty < itemCarts.min_qty"
                          @click.prevent="checkoutOrderBulk(itemCarts)"
                          width="200px"
                          :class="
                            itemCarts.total_qty < itemCarts.min_qty
                              ? 'disabled-checkout'
                              : 'class-checkout'
                          "
                          :style="
                            itemCarts.total_qty < itemCarts.min_qty
                              ? 'color: red !important;'
                              : 'color:white !important;'
                          "
                          outlined
                          >{{ $t("message.label-checkout") }}</v-btn
                        ></v-row
                      >
                    </v-col>
                  </div>
                  <div v-else>
                    <v-col
                      class="d-flex flex-column align-center"
                      style="margin-top: 2%"
                      v-if="itemCarts.is_stock > 0"
                    >
                      <v-row
                        ><v-btn
                          @click.prevent="$router.push('/')"
                          width="200px"
                          style="
                            margin-bottom: 2%;
                            border-color: #ff0090;
                            background-color: transparent;
                            color: black;
                          "
                          outlined
                          >{{ $t("message.label-continue-shop") }}</v-btn
                        ></v-row
                      >
                      <v-row
                        ><v-btn
                          @click.prevent="handleClearCarts(itemCarts.store_id)"
                          width="200px"
                          style="
                            margin-bottom: 2%;
                            border-color: #808080;
                            background-color: gray;
                            color: white;
                          "
                          outlined
                          >{{ $t("message.label-clear-carts") }}</v-btn
                        ></v-row
                      >
                      <v-row
                        ><v-btn
                          @click.prevent="checkoutOrder(itemCarts)"
                          width="200px"
                          style="
                            margin-bottom: 2%;
                            border-color: #ff0090;
                            background-color: #ff0090;
                            color: white;
                          "
                          outlined
                          >{{ $t("message.label-checkout") }}</v-btn
                        ></v-row
                      >
                    </v-col>
                  </div>
                </div>
              </div>
            </v-container>
          </div>
        </div>

        <v-container>
          <ShoppingRules class="my-8" />
        </v-container>

        <div class=" d-flex justify-center" style="margin-bottom: 8rem">
          <router-link to="/" style="text-decoration: none">
              <v-btn
                depressed
                color="#ff0090"
                width="200px"
                style="
                  color: white;
                  padding: 0px;
                  text-transform: none;"
              >
                {{ $t("message.back-to-top") }}
              </v-btn>
          </router-link>
        </div>

        <!-- TEMPORARILY HIDE -->

        <!-- <v-container class="mb-7 me-7">
          <div
            class="d-flex flex-row"
            style="margin-bottom: 5%; margin-top: 2%"
          >
            <div
              style="
                width: 7px;
                height: 100%;
                background-color: #ff0090;
                margin-right: 2%;
              "
            >
              &nbsp;
            </div>
            <div>{{ $t("message.label-other-items") }}</div>
          </div>
          <div>
            <v-row
              style="overflow-x: auto; flex-wrap: nowrap; padding-right: 0px"
              class="row-scroll"
            >
              <v-col
                v-for="list in listProducts"
                style="padding-right: 0px"
                class="parent-style-product"
                cols="4"
                md="2"
                lg="2"
                sm="2"
                :key="list.id"
              >
                <Product :data="list" :loading="is_loading_slide" />
              </v-col>
            </v-row>
          </div>
        </v-container> -->
      </v-stepper-content>
    </v-stepper>
    <Sidebar />
  </div>
</template>

<script>
// import Sidebar from "@/components/Sidebar.vue";
import Sidebar from '../components/developmentv2/Sidebar.vue'
import HeaderPage from '../components/developmentv2/HeaderPage.vue'
import SkeletonCart from "../components/skeleton/SkeletonCart.vue";
import Swal from "sweetalert2";
import Back from "../components/Back.vue";
import moneyFormat from "../helpers/moneyFormat";
import logo from "../assets/favicon-32x32.png";
// import Product from "@/components/Product";
import ShoppingRules from "../components/ShoppingRules.vue";
import formatDecWithoutComa from "../helpers/formatDecWithoutComa";

export default {
  name: "Cart",
  data() {
    return {
      isLoading: false,
      logoLoading: logo,
      cartQty: [],
      temp: 0,
      count: 0,
      stepper: 1,
      is_loading_slide: false,
    };
  },
  components: {
    BackComponent: Back,
    SkeletonCart,
    HeaderPage,
    Sidebar,
    // Product,
    ShoppingRules,
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  methods: {
    formatPostalcode(postalcode) {
      let newPostcode = postalcode.slice(0, 3) + "-" + postalcode.slice(3);
      return newPostcode;
    },
    async fetchCarts() {
      this.isLoading = true;
      let data = await this.$store.dispatch("carts_module/fetchCarts");
      let itemCart = this.$store.state.carts_module.dataCarts;
      //set cart qty
      itemCart.forEach((cartVal) => {
        cartVal.detail.forEach((itemCart) => {
          this.cartQty.push({
            cart_id: itemCart.carts_id,
            qty: itemCart.qty,
          });
        });
      });

      this.isLoading = false;
      return data;
    },
    handleClearCarts(storeId) {
      this.$store.dispatch("carts_module/clearCarts", {
        store_id: storeId,
      });
    },
    handleClearCartsBulk(storeId, groupId) {
      this.$store.dispatch("carts_module/clearCartsBulk", {
        store_id: storeId,
        group_id: groupId,
      });
    },
    moneyFormat(money) {
      return moneyFormat(money);
    },
    formatDecWithoutComa(money) {
      return formatDecWithoutComa(money);
    },
    addQty(cart_id, counter) {
      this.cartQty.filter((el, i) => {
        if (el.cart_id == cart_id) {
          let current_qty = this.cartQty[i].qty;
          let total = current_qty + counter;
          this.temp = total;
          let that = this;
          this.cartQty[i].qty = total;
          setTimeout(function () {
            if (total == that.temp) {
              that.updateQty(cart_id, total);
            }
          }, 200);
        }
      });
    },
    minQty(cart_id, counter) {
      this.cartQty.filter((el, i) => {
        if (el.cart_id == cart_id) {
          let current_qty = this.cartQty[i].qty;
          let total = current_qty - counter;
          this.temp = total;
          this.updateQty = this.updateQty;
          let that = this;
          this.cartQty[i].qty = total;

          setTimeout(function () {
            if (total == that.temp) {
              that.updateQty(cart_id, that.temp);
            } else {
            }
          }, 200);
        }
      });
    },
    deleteCart(cart_id) {
      this.$store.dispatch("carts_module/deleteCart", { cart_id });
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: this.$t("message.title-text-success-delete"),
      });
    },
    getTotalPrice(key, store_id) {
      let total = 0;
      let cart = this.$store.state.carts_module.dataCarts;
      cart[key].detail.forEach((item) => {
        this.cartQty.filter((el) => {
          if (el.cart_id == item.carts_id) {
            total += el.qty * item.price;
          }
        });
      });
      return new Intl.NumberFormat("jp-JP", {
        style: "currency",
        currency: "JPY",
      }).format(total);
    },

    async checkoutOrderBulk(store) {
      this.loading = !this.loading;

      let cart = [];
      setTimeout(() => (this.loading = false), 300);

      // set payload API checkout
      let bulkCart = [];
      bulkCart.push(store);
      if (bulkCart.length == 0) {
        return false;
      }
      let items;
      let totalQty;

      bulkCart.forEach((val) => {
        this.price_orders = val.total_amount_w_delivery_fee_per_store;
        this.store_id = val.store_id;
        this.group_id = val.group_id;
        totalQty = val.total_qty;
        items = val.detail;
        cart.push({
          delivery_fee_bulk: val.delivery_fee_display,
          total_weight_order: 0,
          store_id: val.store_id,
          total_item_price: val.subtotal,
          total_order_price: val.total_amount_w_delivery_fee_per_store,
          total_tax: val.subtotal_tax_amount,
        });
      });
      items.forEach((element) => {
        delete element.store_id;
        delete element.image_url;
        delete element.price;
        delete element.tax;
        delete element.weight;
        delete element.is_stock;
        delete element.is_bulk;
        delete element.cart_id;
      });
      let payload = {
        store_id: this.store_id,
        group_id: this.group_id,
        price_orders: this.price_orders,
        redirect_url_success:
          window.location.origin + process.env.BASE_URL + "bulk/success",
        redirect_url_cancel:
          window.location.origin + process.env.BASE_URL + "carts",
        cart: cart,
        items: items,
        total_qty: totalQty,
      };

      let resp = await this.$store.dispatch(
        "bulk_module/confirmOrder",
        payload
      );
      if (resp.status == 200) {
        this.invoice_no = resp.invoice_no;
        localStorage.setItem("invoice", resp.invoice_no);
        window.open(resp.payment_url, "_self");
      }
    },

    checkoutOrder(itemCarts) {
      let carts = [];
      carts.push(itemCarts);

      if (carts.length == 0) {
        Swal.fire(this.$t("message.no-cart"));
        return false;
      }
      this.$router.push("/select-locker");

      this.$gtag.event("confirm-billing-click", {
        event_category: "Enhanced Ecommerce",
        event_label: "Confirm Billing",
      });
      return this.$store.dispatch("carts_module/checkoutOrder", carts);
    },
    getCartQty(carts_id) {
      let qty = 0;
      this.cartQty.filter((el) => {
        if (el.cart_id == carts_id) {
          qty = el.qty;
        }
      });

      return qty;
    },
    updateQty(cart_id, newQty) {
      let payload = { cart_id, newQty };
      return this.$store.dispatch("carts_module/editQty", payload);
    },
    // async itemList() {
    //   this.is_loading_slide = true;
    //   let data = await this.$store.dispatch("itemList_module/itemList");
    //   this.is_loading_slide = false;
    //   return data;
    // },
  },
  computed: {
    dataCarts() {
      let itemCarts = this.$store.state.carts_module.dataCarts;
      itemCarts.forEach((cartVal) => {
        this.count = 0;
        cartVal.detail.forEach((itemCart) => {
          this.count += itemCart.is_stock;
          if (itemCart.is_bulk != 1) {
            if (itemCart.qty > itemCart.max_qty && itemCart.max_qty != 0) {
              this.updateQty(itemCart.carts_id, itemCart.max_qty);
            }
          }

          /* ====== IF min_qty bulk greater than current_qty update current_qty to min_qty bulk ====== */

          // else {
          //   if (
          //     itemCart.min_qty > itemCart.qty &&
          //     itemCart.max_qty > itemCart.min_qty
          //   ) {
          //     this.updateQty(itemCart.carts_id, itemCart.min_qty);
          //   }
          // }
          if (this.cartQty.length == 0) {
            this.cartQty.push({
              cart_id: itemCart.carts_id,
              qty: itemCart.qty,
            });
          } else {
            let found = this.cartQty.some(
              (el) => el.cart_id === itemCart.carts_id
            );
            if (!found) {
              this.cartQty.push({
                cart_id: itemCart.carts_id,
                qty: itemCart.qty,
              });
            } else {
              this.cartQty.filter((el, i) => {
                if (el.cart_id == itemCart.carts_id) {
                  this.cartQty[i].qty = itemCart.qty;
                }
              });
            }
          }
        });
      });

      return itemCarts;
    },

    totalAmountAll() {
      let totalAmount = 0;
      let cartItem = this.$store.state.carts_module.dataCarts;
      cartItem.forEach((valCart, key) => {
        cartItem[key].items.forEach((item) => {
          totalAmount += item.qty * item.price;
        });
      });
      return new Intl.NumberFormat("jp-JP", {
        style: "currency",
        currency: "JPY",
      }).format(totalAmount);
    },

    // TEMPORARILY HIDE
    // listProducts() {
    //   return this.$store.state.itemList_module.itemListResult;
    // },
  },
  created() {
    this.fetchCarts();
    // this.itemList();
  },
};
</script>

<style scoped>
.v-btn__content {

}
.class-checkout {
  margin-bottom: 2%;
  border-color: #ff0090;
  background-color: #ff0090;
  color: white;
}
.disabled-checkout {
  margin-bottom: 2%;
  border-color: grey;
  background-color: white;
  color: red !important;
}
.class-itemEc {
  border: 1px solid grey;
  border-top: none;
  min-height: 50px;
}
.class-itemBulk {
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  margin: 0;
}
.disabled {
  display: none;
}
.tax-style {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.empty_stock {
  min-height: 200px;
  border: 1px solid gray;
  margin: 0;
}
.font_empty-stock {
  color: grey;
  font-size: 14px;
}
.style-cart {
  min-height: 200px;
  border: 1px solid gray;
  margin: 0;
}
.font-class {
  font-size: 14px;
  margin-right: 5px;
}

.v-stepper__header {
  box-shadow: none;
}
.row-scroll::-webkit-scrollbar {
  display: none;
}
.parent-style-product {
  width: 60%;
  padding: 4px;
}
</style>
