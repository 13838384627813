<template>
  <div class="header-title">
    <v-icon left @click="$router.go(-1)">
      {{ iconArrowLeft }}
    </v-icon>
    {{title_name}}
  </div>
</template>

<script>
import { mdiArrowLeft } from "@mdi/js";

export default {
  name: "Back",
  props: {
    title_name: String,
  },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
    };
  },
};
</script>

<style scoped>
.header-title {
  font-family: roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: -15px;
  margin-bottom: 10px;
  height: 25px;
}
</style>
